<!---- Header ---->
<div class="modal-header" xmlns="http://www.w3.org/1999/html">
  <h4 class="modal-title">
    Inspection Configuration for {{ companyName ?? '-' }}
  </h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="close(true)"> </button>
</div>
<!-- Body -->
<div class="modal-body">
  <div class="row">
    <div class="col-12">
      <div class="question-container">
        <!-- Categories -->
        <div class="row" *ngFor="let category of (jobInspectionCategories$ | async); let i = index">
          <div class="col-12">
            <div class="row" [ngClass]="{'mt-3': i > 0}">
              <div class="col-12">
                <div class="row">
                  <div class="col-10">
                    <h5 class="harmony-blue-text mb-0">{{ category.Name }}</h5>
                  </div>
                  <div class="col-2 text-end align-self-center">
                    <fa-icon [icon]="faAdd"
                             [size]="'lg'"
                             class="harmony-teal-text pointer"
                             [title]="'Add additional question'"
                             (click)="addAdditionalQuestion(category.Id)"></fa-icon>
                  </div>
                </div>
                <hr class="my-1">
              </div>
            </div>
            <!-- Category questions -->
            <ng-container *ngFor="let question of category.Questions">
              <div class="row question-row mt-1">
                <div class="col-9 align-self-center">
                  <fa-icon [icon]="faAdd"
                           [size]="'xs'"
                           *ngIf="question.JobInspectionId != null || question.CompanyId != null"
                           class="harmony-teal-text me-1"></fa-icon>
                  <small>{{ question.QuestionText }}</small>
                </div>
                <div class="col-3 align-self-center pointer">
                  <div class="form-check form-check-inline pointer">
                    <input class="form-check-input pointer"
                           type="radio"
                           [name]="question.Id"
                           [id]="question.Id + '-yes'"
                           [disabled]="true">
                    <label class="form-check-label pointer" [for]="question.Id + '-yes'">
                      Yes
                    </label>
                  </div>
                  <div class="form-check form-check-inline pointer">
                    <input class="form-check-input pointer"
                           type="radio"
                           [name]="question.Id"
                           [id]="question.Id + '-no'"
                           [disabled]="true">
                    <label class="form-check-label pointer" [for]="question.Id + '-no'">
                      No
                    </label>
                  </div>
                  <div class="form-check form-check-inline pointer">
                    <input class="form-check-input pointer"
                           type="radio"
                           [name]="question.Id"
                           [id]="question.Id + '-default'"
                           [disabled]="true"
                           [checked]="true">
                    <label class="form-check-label pointer" [for]="question.Id + '-default'">
                      N/A
                    </label>
                  </div>
                </div>
              </div>
            </ng-container>
            <!-- Additional category questions -->
            <ng-container *ngFor="let question of getAdditionalQuestions(category.Id); let i = index;">
              <div class="row">
                <div class="col-9 align-self-center">
                  <input type="text"
                         class="form-control form-control-sm"
                         [(ngModel)]="question.QuestionText"
                         [ngClass]="{'is-invalid': question.QuestionText?.length <= 0 && invalid}"
                         [placeholder]="'Provide a question'">
                </div>
                <div class="col-3 align-self-center pointer">
                  <div class="form-check form-check-inline pointer">
                    <input class="form-check-input pointer"
                           type="radio"
                           [disabled]="true"
                           [name]="category.Id + '-additional-yes-' + i"
                           [id]="category.Id + '-additional-yes-' + i">
                    <label class="form-check-label pointer" [for]="category.Id + '-additional-yes-' + i">
                      Yes
                    </label>
                  </div>
                  <div class="form-check form-check-inline pointer">
                    <input class="form-check-input pointer"
                           type="radio"
                           [disabled]="true"
                           [name]="category.Id + '-additional-no-' + i"
                           [id]="category.Id + '-additional-no-' + i">
                    <label class="form-check-label pointer" [for]="category.Id + '-additional-no-' + i">
                      No
                    </label>
                  </div>
                  <div class="form-check form-check-inline pointer">
                    <input class="form-check-input pointer"
                           type="radio"
                           [disabled]="true"
                           [name]="category.Id + '-additional-na-' + i"
                           [id]="category.Id + '-additional-na-' + i"
                           [checked]="true">
                    <label class="form-check-label pointer" [for]="category.Id + '-additional-na-' + i">
                      N/A
                    </label>
                  </div>
                  <fa-icon [icon]="faDelete"
                           [size]="'sm'"
                           class="text-danger pointer d-inline float-end"
                           [title]="'Delete additional question'"
                           (click)="deleteAdditionalQuestion(question)"></fa-icon>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!---- Footer ---->
<div class="modal-footer" >
  <button type="button" class="btn btn-primary harmony-purple-button" (click)="save()">Save</button>
  <button type="button" class="btn btn-primary harmony-teal-button" (click)="close(false)">Close</button>
</div>
