import { Component, OnInit } from '@angular/core';
import { CompanyService } from '../../../services/company.service';
import { IPage, IPaginationData } from '../../../shared/models/pagination-data.model';
import {
  faEdit,
  faFileText,
  faPlus,
  faTrash,
  faSortDown
} from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AdAuthService } from '../../../core/ad-auth-service/ad-auth.service';
import { HseService } from 'src/app/services/hse.service';
import { IBranchDto } from '../../../services/models/hse/branch.model';
import { Subject } from 'rxjs';
import { CompanyChartCreateModalComponent } from './company-chart-create-modal/company-chart-create-modal.component';
import { PermissionCodes } from '../../../core/constants/permission-codes';
import {
  CompanyChartInformationModalComponent
} from './company-chart-information-modal/company-chart-information-modal.component';
import { OpenConfirmationModal } from '../../../shared/components/confirmation-modal/confirmation-modal-functions';
import { takeUntil } from 'rxjs/operators';
import { AlertService } from '../../../services/alert.service';
import { environment } from '../../../../environments/environment';
import { IOrderParam } from '../../../shared/directives/sort/order.directive';
import {
  ICompanyChartCategories, ICompanyChartDto,
  ICompanyChartItem, ICompanyChartItemsSearchParam,
  ICompanyChartSearchParam
} from '../../../services/models/hse/hse.model';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService } from '../../../services/storage.service';

@Component({
  selector: 'app-risk-assessment-overview',
  templateUrl: './company-chart-overview.component.html',
  styleUrls: ['./company-chart-overview.component.scss'],
})
export class CompanyChartOverviewComponent implements OnInit {
  // Icons
  protected readonly faView = faFileText;
  protected readonly faDelete = faTrash;
  protected readonly faEdit = faEdit;
  protected readonly faPlus = faPlus;
  protected readonly faSortAsc = faSortDown;
  protected readonly faAdd = faPlus;

  // Component Variables
  public readonly PermissionCodes = PermissionCodes;
  companyChart: ICompanyChartDto;
  companyChartCategories: ICompanyChartCategories[];
  companyChartItems: ICompanyChartItem[];
  branch: IBranchDto;
  environment = environment;
  orderParam: IOrderParam;
  itemOrderParam: IOrderParam;
  private unsubscribe: Subject<any> = new Subject<any>();
  public companyChartSearchParam: ICompanyChartSearchParam = {
    CompanyId: null,
    BranchId: null,
    Year: null,
    ShowAllCompanies: null,
    Criteria: null
  };
  public companyChartItemsSearchParam: ICompanyChartItemsSearchParam = {
    CompanyChartId: null,
    SearchCriteria: ''
  };

  // Permissions
  canCreateNew: boolean;
  canViewAllCompanies: boolean;
  canEditInformation: boolean;
  canDelete: boolean;

  // Shared Components
  paginationData: IPaginationData<ICompanyChartDto> = null;
  page: IPage;
  loading: boolean;
  loadingCompanyChart: boolean;
  noResult: boolean = false;
  companies$ = this.companyService.ActiveMembers$.asObservable();

  constructor(private companyService: CompanyService,
              private modalService: NgbModal,
              private alertService: AlertService,
              private route: ActivatedRoute,
              private hseService: HseService,
              private storage: StorageService,
              public router: Router,
              public authService: AdAuthService) {
  }

  ngOnInit() {
    this.paginationData = {
      DataSet: [],
      CurrentPage: 1,
      PageSize: 15,
      TotalPages: 1
    } as IPaginationData<ICompanyChartDto>;

    this.page = {
      pageNumber: this.paginationData.CurrentPage,
      pageSize: this.paginationData.PageSize
    } as IPage;

    this.orderParam = {
      OrderBy: 'Company.Name',
      OrderDirection: 'asc'
    } as IOrderParam;

    this.itemOrderParam = {
      OrderBy: 'Subject',
      OrderDirection: 'asc'
    } as IOrderParam;

    if (this.authService.CurrentUser) {
      this.setPermissions();
      this.getCompanyCharts();
    }

    this.authService.CurrentUser$
      .subscribe(() => {
        this.setPermissions();
        this.getCompanyCharts();
      });

    this.route.paramMap.subscribe(paramMap => {
      const ccId = paramMap.get('ccId');

      if (ccId) {
        this.openCompanyChart(+ccId);
      }
    });
  }

  setPermissions() {
    this.canViewAllCompanies = this.authService.CheckPermissionByCode(PermissionCodes.HSE_HS_CompanyChart_CompanyFilter);
    this.canCreateNew = this.authService.CheckPermissionByCode(PermissionCodes.HSE_HS_CompanyChart_CreateNew);
    this.canEditInformation = this.authService.CheckPermissionByCode(PermissionCodes.HSE_HS_CompanyChart_EditInformation);
    this.canDelete = this.authService.CheckPermissionByCode(PermissionCodes.HSE_HS_RiskAssessment_DeleteAssessment);
    // Get company ID
    const activeCompanyId: number = this.storage.getSessionItem('cc-active-id');
    this.companyChartSearchParam.CompanyId = activeCompanyId ?? this.authService.CurrentUser.User.CompanyId;
  }

  onPage(page: IPage) {
    this.page = page;
    this.getCompanyCharts();
  }

  orderSearch(param: IOrderParam) {
    this.orderParam = param;
    this.getCompanyCharts();
  }

  setCompanyId() {
    this.storage.storeSessionItem('cc-active-id', this.companyChartSearchParam.CompanyId);
  }

  getCompanyCharts() {
    this.loading = true;

    const p = this.page;
    const o = this.orderParam;

    this.hseService.getCompanyCharts(this.companyChartSearchParam, p, o)
      .subscribe({
        next: (data): void => {
          this.paginationData = Object.assign([], data);
          this.loading = false;
          this.noResult = this.paginationData.DataSet.length === 0;
        },
        error: (): void => {
          this.loading = false;
        }
      });
  }

  loadCompanyChartCategories(companyChartId: number) {
    this.loadingCompanyChart = true;

    this.hseService.getCompanyChartCategoriesByCompanyChartId(companyChartId)
      .subscribe({
        next: (data): void => {
          this.companyChartCategories = Object.assign([], data);

          this.companyChartItemsSearchParam.CompanyChartId = this.companyChart.Id;
          this.hseService.searchCompanyChartItems(this.companyChartItemsSearchParam)
            .subscribe({
              next: (result): void => {
                this.companyChartItems = Object.assign([], result);
                this.loadingCompanyChart = false;
              },
              error: (): void => {
                this.loadingCompanyChart = false;
              }
            });
        },
        error: (): void => {
          this.loadingCompanyChart = false;
        }
      });
  }

  back(shouldRefresh: boolean) {
    this.companyChart = null;
    this.companyChartCategories = null;
    this.companyChartItemsSearchParam.CompanyChartId = null;
    this.companyChartItemsSearchParam.SearchCriteria = '';
    this.router.navigate(['hse/company-chart']).catch();
    if (shouldRefresh) {
      this.getCompanyCharts();
    }
  }

  navigateToCompanyChart(companyChartID: number) {
    this.router.navigate(['hse/company-chart/' + companyChartID]).catch();
  }

  openCompanyChart(companyChartID: number) {
    this.loadingCompanyChart = true;
    this.hseService.getCompanyChartById(companyChartID)
      .subscribe({
        next: (data): void => {
          this.companyChart = Object.assign({}, data);
          this.loadCompanyChartCategories(companyChartID);
        },
        error: (): void => {
          this.loadingCompanyChart = false;
        }
      });
  }

  openCompanyChartCreate() {
    const modalRef = this.modalService.open(CompanyChartCreateModalComponent, {size: 'lg', backdrop: 'static'});

    modalRef.result.then((shouldReload: boolean) => {
      if (shouldReload) {
        this.getCompanyCharts();
      }
    }, () => { });
  }

  openInfoModal() {
    const modalRef = this.modalService.open(CompanyChartInformationModalComponent, {size: 'lg', backdrop: 'static'});
    modalRef.componentInstance.companyId = this.companyChart.CompanyId;
    modalRef.componentInstance.branchId = this.companyChart.BranchId;
    modalRef.componentInstance.hasAccess = this.canEditInformation;
  }

  deleteCompanyChart(companyChart: ICompanyChartDto) {
    const message = 'Are you sure you want to delete this Company chart?';
    OpenConfirmationModal(this.modalService, message)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((answer: boolean) => {
        if (answer) {
          if (companyChart != null) {
            this.loading = true;
            this.hseService.deleteCompanyChart(companyChart.Id)
              .subscribe({
                next: () => {
                  this.alertService.info(`Company chart '${companyChart.CompanyName} - ${companyChart.City}' deleted successfully.`);
                  this.getCompanyCharts();
                  this.loading = false;
                },
                error: () => {
                  this.alertService.info('Failed to delete the Company chart.');
                  this.loading = false;
                }
              });
          }
        }
      });
  }

  resetSearch() {
    // Init pagination data
    this.paginationData = {
      DataSet: [],
      Data: null,
      CurrentPage: 1,
      PageSize: 15,
      TotalPages: 0
    };
    // Init page
    this.page = {
      pageNumber: 1,
      pageSize: 15,
      batched: false
    };
    // Set search param
    this.companyChartItemsSearchParam.SearchCriteria = '';
    this.loadCompanyChartCategories(this.companyChart.Id);
  }
}
