import { AfterViewChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faAngleRight, faAward, faCircleDot } from '@fortawesome/free-solid-svg-icons';
import { AdAuthService } from '../../../core/ad-auth-service/ad-auth.service';
import { PermissionCodes } from '../../../core/constants/permission-codes';

@Component({
  selector: 'app-hse-menu',
  templateUrl: './hse-menu.component.html',
  styleUrls: ['./hse-menu.component.scss'],
})
export class HseMenuComponent implements OnInit, AfterViewChecked {
  // Icons
  faHSE = faAward;
  faMenuItem = faCircleDot;
  faExpandable = faAngleRight;

  // Menu permissions
  incidentImprovement: boolean = false;
  riskAssessment: boolean = false;
  environmentalAssessment: boolean = false;
  companyChart: boolean = false;
  dsr: boolean = false;
  pulseChart: boolean = false;
  emergency: boolean = false;
  environmentalImpactData: boolean = true;
  dashboard: boolean = false;
  documents: boolean = false;
  hseAdmin: boolean = false;
  jobInspection: boolean = false;

  // Component variables
  public logger = (this as any).constructor.name as string;

  constructor(public router: Router,
              public authService: AdAuthService,
              private cdRef: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    if (this.authService.CurrentUser) {
      this.setPermissions();
    }

    this.authService.CurrentUser$.subscribe(() => {
      this.setPermissions();
    });
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  setPermissions() {
    this.incidentImprovement = this.authService.CheckPermissionByCode(PermissionCodes.HSE_IncidentImprovement);
    this.dsr = this.authService.CheckPermissionByCode(PermissionCodes.HSE_DSR);
    this.pulseChart = this.authService.CheckPermissionByCode(PermissionCodes.HSE_PulseChart);
    this.dsr = this.authService.CheckPermissionByCode(PermissionCodes.HSE_DSR);
    this.riskAssessment = this.authService.CheckPermissionByCode(PermissionCodes.HSE_HS_RiskAssessment);
    this.environmentalAssessment = this.authService.CheckPermissionByCode(PermissionCodes.HSE_EnvironmentalAssessment);
    this.companyChart = this.authService.CheckPermissionByCode(PermissionCodes.HSE_HS_CompanyChart);
    this.emergency = this.authService.CheckPermissionByCode(PermissionCodes.HSE_Emergency);
    this.environmentalImpactData = this.authService.CheckPermissionByCode(PermissionCodes.HSE_EnvironmentalImpactData);
    this.documents = this.authService.CheckPermissionByCode(PermissionCodes.HSE_Documents);
    this.hseAdmin = this.authService.CheckPermissionByCode(PermissionCodes.HSE_Admin);
    this.dashboard = this.authService.CheckPermissionByCode(PermissionCodes.HSE_DashBoard);
    this.jobInspection = this.authService.CheckPermissionByCode(PermissionCodes.HSE_JobInspection);
  }
}
