<!---- Header ---->
<div class="modal-header" xmlns="http://www.w3.org/1999/html">
  <h4 class="modal-title">
    {{ JobInspectionId == null ? 'New Job Inspection' : 'Job Inspection | ' + JobInspectionId }}
  </h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="close(true)"> </button>
</div>
<div class="modal-body">
  <div class="row" *ngIf="jobInspection">
    <div class="col-5">
      <div class="row">
        <div class="col-12">
          <h5 class="harmony-blue-text mb-0">Details</h5>
          <hr class="my-1">
        </div>
      </div>
      <div class="row my-1">
        <div class="col-4">
          <b>Company</b>
        </div>
        <div class="col-8">
          <ng-container *ngIf="canViewAllCompanies">
            <select class="form-select form-select-sm pointer"
                    [(ngModel)]="jobInspection.CompanyId"
                    [ngClass]="{'is-invalid': jobInspection.CompanyId <= 0 && invalid}"
                    (change)="loadCategories(jobInspection.Id, jobInspection.CompanyId)"
                    [disabled]="loading || !(companies$ | async)">
              <option [ngValue]="0" disabled selected>Select a company...</option>
              <option *ngFor="let company of (companies$ | async)"
                      [ngValue]="company.CompanyId">{{ company.CompanyName }}</option>
            </select>
          </ng-container>
          <ng-container *ngIf="!canViewAllCompanies">
            <div class="ms-1 my-1">
              {{ companyName }}
            </div>
          </ng-container>
        </div>
      </div>
      <div class="row my-1">
        <div class="col-4">
          <b>File Number</b>
        </div>
        <div class="col-8">
          <input type="text"
                 class="form-control form-control-sm"
                 [(ngModel)]="jobInspection.FileNo"
                 [ngClass]="{'is-invalid': jobInspection.FileNo?.length <= 0 && invalid}"
                 [placeholder]="''">
        </div>
      </div>
      <div class="row my-1">
        <div class="col-4">
          <b>Customer</b>
        </div>
        <div class="col-8">
          <input type="text"
                 class="form-control form-control-sm"
                 [(ngModel)]="jobInspection.Customer"
                 [ngClass]="{'is-invalid': jobInspection.Customer?.length <= 0 && invalid}"
                 [placeholder]="''">
        </div>
      </div>
      <div class="row my-1">
        <div class="col-4">
          <b>Auditor</b>
        </div>
        <div class="col-8">
          <input type="text"
                 class="form-control form-control-sm"
                 [(ngModel)]="jobInspection.Auditor"
                 [ngClass]="{'is-invalid': jobInspection.Auditor?.length <= 0 && invalid}"
                 [placeholder]="''">
        </div>
      </div>
      <div class="row my-1">
        <div class="col-4">
          <b>Location</b>
        </div>
        <div class="col-8">
          <input type="text"
                 class="form-control form-control-sm"
                 [(ngModel)]="jobInspection.Location"
                 [ngClass]="{'is-invalid': jobInspection.Location?.length <= 0 && invalid}"
                 [placeholder]="''">
        </div>
      </div>
      <div class="row my-1">
        <div class="col-4">
          <b>Inspection Type</b>
        </div>
        <div class="col-8">
          <select class="form-select form-select-sm pointer"
                  [(ngModel)]="jobInspection.InspectionTypeId"
                  [ngClass]="{'is-invalid': jobInspection.InspectionTypeId == null && invalid}"
                  [disabled]="loading || !(jobInspectionTypes$ | async)">
            <option [ngValue]="null" disabled selected>Select a type...</option>
            <option *ngFor="let type of (jobInspectionTypes$ | async)"
                    [ngValue]="type.Id">{{ type.Name }}</option>
          </select>
        </div>
      </div>
      <div class="row my-1">
        <div class="col-4">
          <b>Inspection Date</b>
        </div>
        <div class="col-8">
          <app-date-picker [identifier]="'inspectionDate'"
                           [disabled]="loading"
                           [showError]="jobInspection.InspectionDate == null && invalid"
                           (onChange)="jobInspection.InspectionDate = $event"
                           [value]="jobInspection.InspectionDate"
                           [placeholder]="'Date of Inspection'">
          </app-date-picker>
        </div>
      </div>
      <div class="row my-1">
        <div class="col-4">
          <b>Status</b>
        </div>
        <div class="col-8">
          <select class="form-select form-select-sm pointer"
                  [(ngModel)]="jobInspection.StatusId"
                  [ngClass]="{'is-invalid': jobInspection.StatusId == null && invalid}"
                  [disabled]="loading || !(jobInspectionStatuses$ | async)">
            <option [ngValue]="null" disabled selected>Select a status...</option>
            <option *ngFor="let status of (jobInspectionStatuses$ | async)"
                    [ngValue]="status.Id">{{ status.Name }}</option>
          </select>
        </div>
      </div>
      <div class="row my-1">
        <div class="col-12">
          <b>Remarks</b>
        </div>
      </div>
      <div class="row my-1">
        <div class="col-12 text-end">
          <textarea class="form-control form-control-sm"
                    [rows]="3"
                    [maxlength]="4000"
                    [(ngModel)]="jobInspection.Remarks">
          </textarea>
          <small class="harmony-grey-text">{{ jobInspection.Remarks?.length ?? 0 }} / 4000</small>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-12">
          <h5 class="harmony-blue-text mb-0">Follow Up</h5>
          <hr class="my-1">
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-10">
          <b>Based on the findings, is a follow-up required?</b>
        </div>
        <div class="col-2 text-end">
          <div class="form-check form-switch form-switch-sm pointer">
            <input class="form-check-input float-end pointer"
                   type="checkbox"
                   role="switch"
                   [id]="'follow-up-a'"
                   [(ngModel)]="jobInspection.FollowUpA">
          </div>
        </div>
      </div>
      <div class="row" *ngIf="jobInspection.FollowUpA">
        <div class="col-12 text-end">
          <textarea class="form-control form-control-sm"
                    [rows]="3"
                    [maxlength]="4000"
                    [(ngModel)]="jobInspection.FollowUpARemarks">
          </textarea>
          <small class="harmony-grey-text">{{ jobInspection.FollowUpARemarks?.length ?? 0 }} / 2048</small>
        </div>
      </div>
      <div class="row" *ngIf="jobInspection.FollowUpA">
        <div class="col-10">
          <b>Has the follow-up taken place?</b>
        </div>
        <div class="col-2 text-end">
          <div class="form-check form-switch form-switch-sm pointer">
            <input class="form-check-input float-end pointer"
                   type="checkbox"
                   role="switch"
                   [id]="'follow-up-a'"
                   [(ngModel)]="jobInspection.FollowUpB">
          </div>
        </div>
      </div>
      <div class="row" *ngIf="jobInspection.FollowUpB">
        <div class="col-12 text-end">
          <textarea class="form-control form-control-sm"
                    [rows]="3"
                    [maxlength]="4000"
                    [(ngModel)]="jobInspection.FollowUpBRemarks">
          </textarea>
          <small class="harmony-grey-text">{{ jobInspection.FollowUpBRemarks?.length ?? 0 }} / 2048</small>
        </div>
      </div>
    </div>
    <div class="col-7 border-start">
      <div class="question-container">
        <!-- Categories -->
        <div class="row" *ngFor="let category of (jobInspectionCategories$ | async); let i = index">
          <div class="col-12">
            <div class="row" [ngClass]="{'mt-3': i > 0}">
              <div class="col-12">
                <div class="row">
                  <div class="col-10">
                    <h5 class="harmony-blue-text mb-0">{{ category.Name }}</h5>
                  </div>
                </div>
                <hr class="my-1">
              </div>
            </div>
            <!-- Category questions -->
            <ng-container *ngFor="let question of category.Questions">
              <div class="row question-row mt-1">
                <div class="col-9 align-self-center">
                  <fa-icon [icon]="faAdd"
                           [size]="'xs'"
                           *ngIf="question.JobInspectionId != null || question.CompanyId != null"
                           class="harmony-teal-text me-1"></fa-icon>
                  <small>{{ question.QuestionText }}</small>
                </div>
                <div class="col-3 align-self-center pointer">
                  <div class="form-check form-check-inline pointer">
                    <input class="form-check-input pointer"
                           type="radio"
                           [name]="question.Id"
                           [id]="question.Id + '-yes'"
                           (change)="setAnswer(question.Id, true)"
                           [checked]="getAnswer(question.Id) == true">
                    <label class="form-check-label pointer" [for]="question.Id + '-yes'">
                      Yes
                    </label>
                  </div>
                  <div class="form-check form-check-inline pointer">
                    <input class="form-check-input pointer"
                           type="radio"
                           [name]="question.Id"
                           [id]="question.Id + '-no'"
                           (change)="setAnswer(question.Id, false)"
                           [checked]="getAnswer(question.Id) == false">
                    <label class="form-check-label pointer" [for]="question.Id + '-no'">
                      No
                    </label>
                  </div>
                  <div class="form-check form-check-inline pointer">
                    <input class="form-check-input pointer"
                           type="radio"
                           [name]="question.Id"
                           [id]="question.Id + '-default'"
                           [checked]="getAnswer(question.Id) == null">
                    <label class="form-check-label pointer" [for]="question.Id + '-default'">
                      N/A
                    </label>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!---- Footer ---->
<div class="modal-footer" >
  <button type="button" class="btn btn-primary harmony-purple-button" (click)="save()">Save</button>
  <button type="button" class="btn btn-primary harmony-teal-button" (click)="close(false)">Close</button>
</div>
