import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, of, Subject, take } from 'rxjs';
import { IJobInspection, IJobInspectionOverview, IJobInspectionSearch, IJobInspectionStatus, IJobInspectionType } from '../../../services/models/hse/job-inspection.model';
import { ApiService } from '../../../services/api.service';
import { IPage, IPaginationData } from '../../../shared/models/pagination-data.model';
import { IOrderParam } from '../../../shared/directives/sort/order.directive';
import { PermissionCodes } from '../../../core/constants/permission-codes';
import { AdAuthService } from '../../../core/ad-auth-service/ad-auth.service';
import { ActivatedRoute } from '@angular/router';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { JobInspectionModalComponent } from './job-inspection-modal/job-inspection-modal.component';
import { CompanyService } from '../../../services/company.service';
import { faBoxOpen, faEdit, faEye, faSortUp, faTrash } from '@fortawesome/free-solid-svg-icons';
import { environment } from '../../../../environments/environment';
import { JobInspectionConfigureModalComponent } from './job-inspection-configure-modal/job-inspection-configure-modal.component';
import { OpenConfirmationModal } from '../../../shared/components/confirmation-modal/confirmation-modal-functions';
import { AlertService } from '../../../services/alert.service';

@Component({
  selector: 'app-job-inspection-overview',
  templateUrl: './job-inspection-overview.component.html',
  styleUrls: ['./job-inspection-overview.component.scss']
})
export class JobInspectionOverviewComponent implements OnInit, OnDestroy {
  // Icons
  protected readonly faSortDesc = faSortUp;
  protected readonly faEditIcon = faEdit;
  protected readonly faView = faEye;
  protected readonly faTrash = faTrash;
  protected readonly faEmpty = faBoxOpen;

  // Component variables
  searchParam: IJobInspectionSearch;
  paginationData: IPaginationData<IJobInspectionOverview>;
  orderParam: IOrderParam;
  page: IPage;
  companies$ = this.companyService.ActiveMembers$.asObservable();
  jobInspectionTypes$: Observable<IJobInspectionType[]>;
  jobInspectionStatuses$: Observable<IJobInspectionStatus[]>;

  // Permissions
  companyFilterAll: boolean = false;
  canConfigure: boolean = false;
  canDelete: boolean = false;

  // General Variables
  loading: boolean = false;
  private unsubscribe: Subject<any> = new Subject<any>();
  public readonly PermissionCodes = PermissionCodes;
  protected readonly environment = environment;

  constructor(public authService: AdAuthService,
              private companyService: CompanyService,
              private route: ActivatedRoute,
              private modalService: NgbModal,
              private alertService: AlertService,
              private api: ApiService) { }

  ngOnInit(): void {
    if (this.authService.CurrentUser) {
      this.setPermissions();
    }
    // Subscribe to user to always check if something changes
    this.authService.CurrentUser$.subscribe(() => {
      this.setPermissions();
    });
    this.loadTypes();
    this.loadStatuses();
  }

  ngOnDestroy() {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  setPermissions() {
    // Check for Company Filter permission, only users with this permission can view inspections for all members.
    this.companyFilterAll = this.authService.CheckPermissionByCode(PermissionCodes.HSE_JobInspection_CompanyFilter);
    this.canConfigure = this.authService.CheckPermissionByCode(PermissionCodes.HSE_JobInspection_ConfigureQuestions);
    this.canDelete = this.authService.CheckPermissionByCode(PermissionCodes.HSE_JobInspection_Delete);
    this.initPage();
    if (this.companyFilterAll === false) {
      this.searchParam.CompanyId = this.authService.CurrentUser.User.CompanyId;
    }
    this.checkRoutes();
    this.searchJobInspections();
  }

  initPage(){
    // Set search param
    this.searchParam = {
      Id: null,
      CompanyId: null,
      InspectionTypeId: null,
      StatusId: null,
      SearchText: null
    };

    // Init pagination data
    this.paginationData = {
      DataSet: [],
      Data: null,
      CurrentPage: 1,
      PageSize: 30,
      TotalPages: 0
    };

    // Init page
    this.page = {
      pageNumber: this.paginationData.CurrentPage,
      pageSize: this.paginationData.PageSize,
      batched: false
    };

    // Set default ordering
    this.orderParam = {
      OrderBy: 'InspectionDate',
      OrderDirection: 'desc'
    } as IOrderParam;
  }

  checkRoutes() {
    this.route.paramMap.pipe(take(1)).subscribe(paramMap => {
      const jobInspectionId = paramMap.get('id');
      // Open a specific incident
      if (jobInspectionId) {
        this.openJobInspection(+jobInspectionId);
      }
    });
  }

  onPage(page: IPage) {
    this.page = page;
    this.searchJobInspections();
  }

  orderSearch(param: IOrderParam) {
    this.orderParam = param;
    this.searchJobInspections();
  }

  searchJobInspections() {
    this.loading = false;

    const p = this.page;
    const o = this.orderParam;

    if (this.searchParam.SearchText?.length <= 0) {
      this.searchParam.SearchText = null;
    }

    this.api.post(`JobInspection/Search?pageNumber=${p.pageNumber}&pageSize=${p.pageSize}&orderBy=${o.OrderBy}&order=${o.OrderDirection}`, this.searchParam)
      .pipe(takeUntil(this.unsubscribe)).subscribe({
      next: (data) => {
        if (data) {
          this.paginationData = Object.assign({}, data);
        }
        this.loading = false;
      },
      error: () => {
        this.loading = false;
      }
    });
  }

  newJobInspection() {
    // Open modal to create a new job inspection
    const modalRef = this.modalService.open(JobInspectionModalComponent, {
      size: 'xl',
      windowClass: 'modal-3xl',
      backdrop: 'static'
    });
    modalRef.componentInstance.CompanyId = this.searchParam.CompanyId ?? 0;
    modalRef.componentInstance.JobInspectionId = 0;
    modalRef.componentInstance.InitModal();
    // On modal close, read the result and apply logic
    modalRef.result.then((result: IJobInspection) => {
      if (result) {
        this.searchJobInspections();
      }
    });
  }

  openConfigModal() {
    // Open modal to configure the questions for a company
    const modalRef = this.modalService.open(JobInspectionConfigureModalComponent, {
      size: 'xl',
      backdrop: 'static'
    });
    modalRef.componentInstance.CompanyId = this.searchParam.CompanyId ?? 0;
    modalRef.componentInstance.InitModal();
    // On modal close, read the result and apply logic
    modalRef.result.then((result: IJobInspection) => {
      if (result) {
        this.searchJobInspections();
      }
    });
  }

  openJobInspection(id: number) {
    // Open modal to create a new job inspection
    const modalRef = this.modalService.open(JobInspectionModalComponent, {
      size: 'xl',
      windowClass: 'modal-3xl',
      backdrop: 'static'
    });
    modalRef.componentInstance.JobInspectionId = id;
    modalRef.componentInstance.InitModal();
    // On modal close, read the result and apply logic
    modalRef.result.then((result: IJobInspection) => {
      if (result) {
        this.searchJobInspections();
      }
    });
  }

  deleteInspection(id: number) {
    const message = `This Job Inspection and all related answer data will be deleted. This action can not be undone. \n\n Continue?`;
    OpenConfirmationModal(this.modalService, message)
      .pipe(
        takeUntil(this.unsubscribe),
        switchMap(answer => {
          if (answer) {
            const successMessage = `Job inspection successfully deleted.`;
            return this.api.delete(`JobInspection/Delete/${id}`)
              .pipe(
                tap(() => {
                  this.alertService.success(successMessage);
                  this.searchJobInspections();
                }));
          }
        })
      ).subscribe();
  }

  // Lookups
  loadTypes() {
    this.loading = true;

    this.api.get('JobInspection/Types').pipe(
      takeUntil(this.unsubscribe)
    ).subscribe({
      next: data => {
        if (data) {
          this.jobInspectionTypes$ = of(data);
        }
        this.loading = false;
      },
      error: () => {
        this.loading = false;
      }
    });
  }

  loadStatuses() {
    this.loading = true;

    this.api.get('JobInspection/Statuses').pipe(
      takeUntil(this.unsubscribe)
    ).subscribe({
      next: data => {
        if (data) {
          this.jobInspectionStatuses$ = of(data);
        }
        this.loading = false;
      },
      error: () => {
        this.loading = false;
      }
    });
  }
}
