<div class="modal-header">
  <h4 class="modal-title">
    {{ ComponentId ? 'Manage Permission' : 'New Permission' }}
  </h4>
  <button type="button" class="btn-close" aria-label="Close" [disabled]="loading" (click)="activeModal.dismiss(false)">
  </button>
</div>
<div class="modal-body">
  <ng-container *ngIf="permission">
    <div class="row">
      <div class="col-6">
        <h5 class="harmony-teal-text mb-0">Details</h5>
        <hr class="my-1">
      </div>
      <div class="col-6">
        <h5 class="harmony-teal-text mb-0">Roles assigned to</h5>
        <hr class="my-1">
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <div class="row mt-1">
          <div class="col-12">
            <select name="module"
                    class="form-select form-select-sm"
                    id="module"
                    [ngClass]="{'is-invalid': !permission.ModuleId && invalid}"
                    [(ngModel)]="permission.ModuleId">
              <option [ngValue]="null" selected disabled>Select a module...</option>
              <option *ngFor="let module of (modules$ | async)" [ngValue]="module.Id">
                {{module.Name}}
              </option>
            </select>
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-12" style="line-height: 11px;">
            <input type="text"
                   class="form-control form-control-sm"
                   [placeholder]="'Permission Name | Action'"
                   [ngClass]="{'is-invalid': (!permission.Name || permission.Name.length < 3) && invalid}"
                   [(ngModel)]="permission.Name">
            <small class="harmony-grey-text" style="font-size: 10px; margin-left: 2px;">Required. Minimum length of 3 characters.</small>
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-10" style="line-height: 11px;">
            <input type="text"
                   class="form-control form-control-sm text-uppercase"
                   [ngClass]="{'is-invalid': uniqueCodeExists || (!permission.Code && invalid)}"
                   [disabled]="permission.Id != null"
                   (keyup)="updateCode($event)"
                   [placeholder]="'ENTER CODE'"
                   [maxLength]="8"
                   [minLength]="8"
                   [(ngModel)]="permission.Code">
            <small class="harmony-grey-text" style="font-size: 10px; margin-left: 2px;">Unique 8 character code e.g. EXPLCODE</small>
            <small *ngIf="uniqueCodeExists" class="required-text">Unique code already used</small>
          </div>
          <div class="col-2 text-center pt-1">
            <fa-icon [icon]="faValid" [size]="'lg'" class="harmony-green-text" *ngIf="!loading && !(uniqueCodeExists || permission.Code?.length <= 7)"></fa-icon>
            <fa-icon [icon]="faInvalid" [size]="'lg'" class="text-danger" *ngIf="!loading && (uniqueCodeExists || permission.Code?.length <= 7)"></fa-icon>
            <app-loader *ngIf="loading" [Width]="25"></app-loader>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-12">
            <textarea class="form-control form-control-sm"
                      name="componentDescription"
                      id="componentDescription"
                      [(ngModel)]="permission.Description"
                      [placeholder]="'Description of access granted by this permission'"
                      cols="30"
                      [rows]="5">
            </textarea>
          </div>
        </div>
      </div>
      <div class="col-6 permission-role-list">
        <div class="row">
          <div class="col-12">
            <ng-container *ngIf="(roles$ | async)?.length <= 0">
              <small class="harmony-grey-text">{{ permission.Id == null ? 'Save the new module before components can be linked.' : 'Permission not yet linked to any roles...' }}</small>
            </ng-container>
            <ng-container *ngIf="(roles$ | async)?.length > 0">
              <div class="row result-row" *ngFor='let role of (roles$ | async)'>
                <div class="col-11 ps-0">
                  {{role.Name}}
                </div>
                <div class="col-1 pointer" *ngIf="role.Description && role.Description.length > 0" [title]="role.Description">
                  <fa-icon [icon]="faInfo" class="harmony-grey-text"></fa-icon>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
<div class="modal-footer">
  <app-loader *ngIf="loading" class="me-2" [Width]="20"></app-loader>
  <button type="button" class="btn btn-primary harmony-purple-button" (click)="saveComponent()">Save</button>
  <button type="button" class="btn btn-primary harmony-teal-button" (click)="close()">Close</button>
</div>

