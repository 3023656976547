import { Component, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AdAuthService } from '../../../../core/ad-auth-service/ad-auth.service';
import { AlertService } from '../../../../services/alert.service';
import { Observable, of, Subject } from 'rxjs';
import {
  IJobInspection,
  IJobInspectionAnswer,
  IJobInspectionCategory,
  IJobInspectionStatus,
  IJobInspectionType
} from '../../../../services/models/hse/job-inspection.model';
import { takeUntil } from 'rxjs/operators';
import { ApiService } from '../../../../services/api.service';
import { CompanyService } from '../../../../services/company.service';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { PermissionCodes } from '../../../../core/constants/permission-codes';

@Component({
  selector: 'app-job-inspection-edit',
  templateUrl: './job-inspection-modal.component.html',
  styleUrls: ['./job-inspection-modal.component.scss']
})
export class JobInspectionModalComponent implements OnDestroy {
  // Icons
  protected readonly faAdd = faPlus;

  // Component Variables
  public JobInspectionId: number = 0;
  public CompanyId: number = 0;
  jobInspectionCategories$: Observable<IJobInspectionCategory[]>;
  companies$ = this.companyService.ActiveMembers$.asObservable();
  jobInspection: IJobInspection;
  invalid: boolean = false;
  jobInspectionTypes$: Observable<IJobInspectionType[]>;
  jobInspectionStatuses$: Observable<IJobInspectionStatus[]>;

  // Permissions
  canViewAllCompanies: boolean;
  companyName: string;

  // General variables
  loading: boolean = false;
  private unsubscribe: Subject<any> = new Subject<any>();

  constructor(private alertService: AlertService,
              private companyService: CompanyService,
              private activeModal: NgbActiveModal,
              private api: ApiService,
              private authService: AdAuthService) { }

  public InitModal() {
    this.loadTypes();
    this.loadStatuses();
    // Check for Company Filter permission, only users with this permission can create inspections for all members.
    this.canViewAllCompanies = this.authService.CheckPermissionByCode(PermissionCodes.HSE_JobInspection_CompanyFilter);
    if (this.JobInspectionId > 0) {
      this.loadJobInspection(this.JobInspectionId);
    } else {
      this.loadCategories(0, this.CompanyId);
      this.companyName = this.companyService.ActiveMembers$.getValue().find(x => x.CompanyId === this.CompanyId)?.CompanyName;
      this.jobInspection = {
        Id: 0,
        CompanyId: this.CompanyId,
        CompanyName: this.authService.CurrentUser.User.Company.Name,
        FileNo: null,
        Customer: null,
        Auditor: null,
        Location: null,
        InspectionDate: null,
        InspectionTypeId: null,
        StatusId: 1,
        Remarks: null,
        FollowUpA: null,
        FollowUpARemarks: null,
        FollowUpB: null,
        FollowUpBRemarks: null,
        Answers: []
      } as IJobInspection;
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  loadCategories(id: number, companyId: number) {
    this.loading = true;

    this.api.get(`JobInspection/Categories/${id ?? 0}/${companyId ?? 0}`).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe({
      next: data => {
        if (data) {
          this.jobInspectionCategories$ = of(data);
        }
        this.loading = false;
      },
      error: () => {
        this.loading = false;
      }
    });
  }

  loadJobInspection(id: number) {
    this.loading = true;

    this.api.get(`JobInspection/${id}`).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe({
        next: (data: IJobInspection) => {
          if (data) {
            if (this.canViewAllCompanies === false && data.CompanyId !== this.authService.CurrentUser.User.CompanyId) {
              this.alertService.warn('You do not have access to view this Job Inspection');
              this.close(true);
            } else {
              this.jobInspection = Object.assign({}, data);
              this.loadCategories(this.JobInspectionId, this.jobInspection.CompanyId);
            }
          }
          this.loading = false;
        },
      error: () => {
        this.loading = false;
      }
    });
  }

  getAnswer(questionId: number) {
    const questionIndex = this.jobInspection.Answers.findIndex(s => s.QuestionId === questionId);
    if (questionIndex >= 0) {
      return this.jobInspection.Answers[questionIndex].AnswerValue;
    } else {
      return null;
    }
  }

  setAnswer(questionId: number, value: boolean) {
    if (questionId != null && value != null) {
      const questionIndex = this.jobInspection.Answers.findIndex(s => s.QuestionId === questionId);

      if (questionIndex >= 0) {
        this.jobInspection.Answers[questionIndex].AnswerValue = value;
      } else {
        const newAnswer = {
          Id: null,
          JobInspectionId: this.jobInspection.Id,
          QuestionId: questionId,
          AnswerValue: value
        } as IJobInspectionAnswer;

        this.jobInspection.Answers.push(newAnswer);
      }
    }
  }

  save() {
    if (this.jobInspection.Id == null || this.jobInspection.Id <= 0) {
      this.createJobInspection();
    } else {
      this.updateJobInspection();
    }
  }

  canSave() {
    return this.jobInspection.CompanyId > 0 &&
      this.jobInspection.FileNo?.length > 0 &&
      this.jobInspection.Customer?.length > 0 &&
      this.jobInspection.Auditor?.length > 0 &&
      this.jobInspection.Location?.length > 0 &&
      this.jobInspection.InspectionTypeId > 0 &&
      this.jobInspection.InspectionDate?.length > 0 &&
      this.jobInspection.StatusId > 0;
  }

  createJobInspection() {
    if (this.canSave()) {
      this.invalid = false;
      this.api.post('JobInspection/Create', this.jobInspection).pipe(
        takeUntil(this.unsubscribe)
      ).subscribe({
          next: (data: IJobInspection) => {
            if (data) {
              this.jobInspection = Object.assign({}, data);
              this.alertService.info('Job Inspection successfully created.');
              this.close(true);
            }
          },
          error: (error) => {
            this.alertService.error(error.Meta.Message);
          }
        });
    } else {
      this.invalid = true;
      this.alertService.warn('Please fill in all required fields.');
    }
  }

  updateJobInspection() {
    if (this.canSave()) {
      this.invalid = false;
      this.api.put('JobInspection/Update', this.jobInspection).pipe(
        takeUntil(this.unsubscribe)
      ).subscribe({
        next: (data) => {
          if (data) {
            this.jobInspection = Object.assign({}, data);
            this.alertService.info('Job Inspection successfully updated.');
            this.close(true);
          }
        },
        error: (error) => {
          this.alertService.error(error.Meta.Message);
        }
      });
    } else {
      this.invalid = true;
      this.alertService.warn('Please fill in all required fields.');
    }
  }

  close(shouldReload: boolean) {
    this.activeModal.close(shouldReload);
  }

  // Lookups
  loadTypes() {
    this.loading = true;

    this.api.get('JobInspection/Types').pipe(
      takeUntil(this.unsubscribe)
    ).subscribe({
      next: data => {
        if (data) {
          this.jobInspectionTypes$ = of(data);
        }
        this.loading = false;
      },
      error: () => {
        this.loading = false;
      }
    });
  }

  loadStatuses() {
    this.loading = true;

    this.api.get('JobInspection/Statuses').pipe(
      takeUntil(this.unsubscribe)
    ).subscribe({
      next: data => {
        if (data) {
          this.jobInspectionStatuses$ = of(data);
        }
        this.loading = false;
      },
      error: () => {
        this.loading = false;
      }
    });
  }
}
