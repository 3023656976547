import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import {
  IAnalysisAction,
  IAnalysisCauseFactor,
  IAnalysisCauseOccurrence,
  IAnalysisCauseSpecific,
  IAnalysisStatus,
  ICompanyChartCategories,
  ICompanyChartItem,
  ICompanyChartSearchParam,
  ICompanyChartStatus,
  IEnvironmentalAssessmentDto,
  IEnvironmentalAssessmentAnswer,
  IEnvironmentalAssessmentAnswerStatus,
  IEnvironmentalAssessmentGroup,
  IEnvironmentalAssessmentItem,
  IEnvironmentalAssessmentList,
  IEnvironmentalAssessmentListParam,
  IEnvironmentalSeverity,
  IExposure,
  IHandlingSeverity,
  IIncident,
  IIncidentImprovementListParam,
  IIncidentLevel,
  IIncidentList,
  IInfluence,
  ILevelOfMeasures,
  ILikeliness,
  IMeasure,
  IRegistrationDiscipline,
  IRegistrationSource,
  IRegistrationType,
  IRiskAssessmentDto,
  IRiskAssessmentAnswer,
  IRiskAssessmentAnswerStatus,
  IRiskAssessmentGroup,
  IRiskAssessmentItem,
  IRiskAssessmentList,
  IRiskAssessmentListParam,
  ISeverity,
  ICompanyChartCategory,
  ICompanyChartDocument,
  ICompanyChartItemsSearchParam,
  ICompanyChartDto,
  IPhrasesSearchparam,
  IPSPhrase,
  IHRPhrase,
  IEnvironmentalAssessmentStatus,
  IRiskAssessmentStatus
} from './models/hse/hse.model';
import { AlertService } from './alert.service';
import { ApiService } from './api.service';
import { takeUntil } from 'rxjs/operators';
import { IPage, IPaginationData } from '../shared/models/pagination-data.model';
import { IActiveCompany } from './models/member.model';
import { ICurrency } from './models/portal/currency.model';
import { IOrderParam } from '../shared/directives/sort/order.directive';
import { IBranchResultDto, IBranchSearchDto } from './models/hse/branch.model';

@Injectable({
  providedIn: 'root'
})
export class HseService implements OnDestroy {
  // Lookups
  AnalysisStatus: IAnalysisStatus[];
  HandlingSeverity: IHandlingSeverity[];
  RegistrationDiscipline: IRegistrationDiscipline[];
  RegistrationDiscipline$: BehaviorSubject<IRegistrationDiscipline[]> = new BehaviorSubject<IRegistrationDiscipline[]>([]);
  RegistrationSource: IRegistrationSource[];
  RegistrationType: IRegistrationType[];
  RegistrationType$: BehaviorSubject<IRegistrationType[]> = new BehaviorSubject<IRegistrationType[]>([]);
  AnalysisAction: IAnalysisAction[];
  AnalysisCauseSpecific: IAnalysisCauseSpecific[];
  AnalysisCauseSpecific$: BehaviorSubject<IAnalysisCauseSpecific[]> = new BehaviorSubject<IAnalysisCauseSpecific[]>([]);
  AnalysisCauseFactor: IAnalysisCauseFactor[];
  AnalysisCauseOccurrence: IAnalysisCauseOccurrence[];
  IncidentLevel: IIncidentLevel[];
  Currency: ICurrency[];
  Companies: IActiveCompany[];
  Exposure: IExposure[];
  Exposure$: BehaviorSubject<IExposure[]> = new BehaviorSubject<IExposure[]>([]);
  Likeliness: ILikeliness[];
  Likeliness$: BehaviorSubject<ILikeliness[]> = new BehaviorSubject<ILikeliness[]>([]);
  Severity: ISeverity[];
  Severity$: BehaviorSubject<ISeverity[]> = new BehaviorSubject<ISeverity[]>([]);
  EnvironmentalSeverity: IEnvironmentalSeverity[];
  EnvironmentalSeverity$: BehaviorSubject<IEnvironmentalSeverity[]> = new BehaviorSubject<IEnvironmentalSeverity[]>([]);
  Influence: IInfluence[];
  Influence$: BehaviorSubject<IInfluence[]> = new BehaviorSubject<IInfluence[]>([]);
  Measure: IMeasure[];
  Measure$: BehaviorSubject<IMeasure[]> = new BehaviorSubject<IMeasure[]>([]);
  LevelOfMeasures: ILevelOfMeasures[];
  LevelOfMeasures$: BehaviorSubject<ILevelOfMeasures[]> = new BehaviorSubject<ILevelOfMeasures[]>([]);
  RiskAssessmentAnswerStatus: IRiskAssessmentAnswerStatus[];
  RiskAssessmentAnswerStatus$: BehaviorSubject<IRiskAssessmentAnswerStatus[]> = new BehaviorSubject<IRiskAssessmentAnswerStatus[]>([]);
  RiskAssessmentStatus: IRiskAssessmentStatus[];
  RiskAssessmentStatus$: BehaviorSubject<IRiskAssessmentStatus[]> = new BehaviorSubject<IRiskAssessmentStatus[]>([]);
  EnvironmentalAssessmentAnswerStatus: IEnvironmentalAssessmentAnswerStatus[];
  EnvironmentalAssessmentAnswerStatus$: BehaviorSubject<IEnvironmentalAssessmentAnswerStatus[]> = new BehaviorSubject<IEnvironmentalAssessmentAnswerStatus[]>([]);
  EnvironmentalAssessmentStatus: IEnvironmentalAssessmentStatus[];
  EnvironmentalAssessmentStatus$: BehaviorSubject<IEnvironmentalAssessmentStatus[]> = new BehaviorSubject<IEnvironmentalAssessmentStatus[]>([]);
  CompanyChartStatus: ICompanyChartStatus[];
  CompanyChartStatus$: BehaviorSubject<ICompanyChartStatus[]> = new BehaviorSubject<ICompanyChartStatus[]>([]);


  // General Variables
  loading: boolean = false;
  private unsubscribe: Subject<any> = new Subject<any>();
  Incident: IIncident;

  constructor(private alertService: AlertService,
              public apiService: ApiService) {
    if (!this.AnalysisStatus || this.AnalysisStatus.length <= 0) {
      this.loadAnalysisStatus();
    }

    if (!this.HandlingSeverity || this.HandlingSeverity.length <= 0) {
      this.loadHandlingSeverity();
    }

    if (!this.RegistrationDiscipline || this.RegistrationDiscipline.length <= 0) {
      this.loadRegistrationDiscipline();
    }

    if (!this.RegistrationType || this.RegistrationType.length <= 0) {
      this.loadRegistrationType();
    }

    if (!this.RegistrationSource || this.RegistrationSource.length <= 0) {
      this.loadRegistrationSource();
    }

    if (!this.AnalysisAction || this.AnalysisAction.length <= 0) {
      this.loadAnalysisAction();
    }

    if (!this.AnalysisCauseSpecific || this.AnalysisCauseSpecific.length <= 0) {
      this.loadAnalysisCauseSpecific();
    }

    if (!this.AnalysisCauseFactor || this.AnalysisCauseFactor.length <= 0) {
      this.loadAnalysisCauseFactor();
    }

    if (!this.AnalysisCauseOccurrence || this.AnalysisCauseOccurrence.length <= 0) {
      this.loadAnalysisCauseOccurrence();
    }

    if (!this.IncidentLevel || this.IncidentLevel.length <= 0) {
      this.loadIncidentLevel();
    }

    if (!this.Companies || this.Companies.length <= 0) {
      this.loadAllCompanies();
    }

    if (!this.Currency || this.Currency.length <= 0) {
      this.loadCurrencies();
    }

    if (!this.Exposure || this.Exposure.length <= 0) {
      this.getExposure();
    }

    if (!this.Likeliness || this.Exposure.length <= 0) {
      this.getLikeliness();
    }

    if (!this.Severity || this.Severity.length <= 0) {
      this.getSeverity();
    }

    if (!this.EnvironmentalSeverity || this.EnvironmentalSeverity.length <= 0) {
      this.getEnvironmentalSeverity();
    }

    if (!this.Influence || this.Influence.length <= 0) {
      this.getInfluence();
    }

    if (!this.Measure || this.Measure.length <= 0) {
      this.getMeasure();
    }

    if (!this.LevelOfMeasures || this.LevelOfMeasures.length <= 0) {
      this.getLevelOfMeasures();
    }
    if (!this.RiskAssessmentAnswerStatus || this.RiskAssessmentAnswerStatus.length <= 0) {
      this.getRiskAssessmentAnswerStatus();
    }
    if (!this.EnvironmentalAssessmentAnswerStatus || this.EnvironmentalAssessmentAnswerStatus.length <= 0) {
      this.getEnvironmentalAssessmentAnswerStatus();
    }
    if (!this.RiskAssessmentStatus || this.RiskAssessmentStatus.length <= 0) {
      this.getRiskAssessmentStatus();
    }
    if (!this.EnvironmentalAssessmentStatus || this.EnvironmentalAssessmentStatus.length <= 0) {
      this.getEnvironmentalAssessmentStatus();
    }

    if (!this.CompanyChartStatus || this.CompanyChartStatus.length <= 0) {
      this.getCompanyChartStatus();
    }

    this.loading = false;
  }

  // Public methods
  public getIncidentsList(incidentListParam: IIncidentImprovementListParam, o: IOrderParam, p: IPage): Observable<IPaginationData<IIncidentList>> {
    return this.apiService
      .post(`IncidentImprovement/ListIncidentImprovement?pageNumber=${p.pageNumber}&pageSize=${p.pageSize}&orderBy=${o.OrderBy}&order=${o.OrderDirection}`, incidentListParam)
      .pipe(takeUntil(this.unsubscribe));
  }

  public getIncident(IncidentNumber: number) {
    return this.apiService
      .get('IncidentImprovement/GetIncidentImprovement/' + IncidentNumber).pipe(
      takeUntil(this.unsubscribe));
  }

  public updateIncident(incident: IIncident) {
    return this.apiService
      .post('IncidentImprovement/UpdateIncidentImprovement', incident).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  public deleteIncident(IncidentNumber: number) {
    return this.apiService.post('IncidentImprovement/DeleteIncidentImprovement/' + IncidentNumber).pipe(
      takeUntil(this.unsubscribe));
  }

  public findAnalysisDescription(AnalysisStatusId: number) {
    if (!this.AnalysisStatus) {
      return;
    }

    const analysisStatus = this.AnalysisStatus.find(element => element.AnalysisStatusId === AnalysisStatusId);

    if (!analysisStatus) {
      return;
    } else {
      return analysisStatus.Description;
    }
  }

  public findCompanyName(CompanyId: number) {
    if (!this.Companies) {
      return;
    }

    const companyName = this.Companies.find(element => element.CompanyId === CompanyId);

    if (!companyName) {
      return;
    } else {
      return companyName.CompanyName;
    }
  }

  public findLevelDescription(Level: string) {
    if (!this.IncidentLevel) {
      return;
    }

    const levelDescription = this.IncidentLevel.find(element => element.Description === Level);

    if (levelDescription === undefined) {
      return;
    } else {
      return levelDescription.Description;
    }
  }

  public excelExport() {
    return this.apiService.getFile('IncidentImprovement/IncidentImprovementExcelExport').pipe(
      takeUntil(this.unsubscribe)
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  loadAnalysisStatus() {
    this.loading = true;

    return this.apiService.get('IncidentImprovement/ListAnalysisStatus').pipe(
      takeUntil(this.unsubscribe)
    ).subscribe({
      next: (data: IAnalysisStatus[]) => {
        if (data) {
          this.AnalysisStatus = Object.assign([], data);
        }
        this.loading = false;
      },
      error: () => {
        this.alertService.error('An Error Occurred: LoadAnalysisStatus');
      }
    });
  }

  loadHandlingSeverity() {

    this.loading = true;

    this.apiService.get('IncidentImprovement/ListHandlingSeverity').pipe(
      takeUntil(this.unsubscribe),
    ).subscribe({
      next: (data) => {
        if (data) {
          this.HandlingSeverity = Object.assign([], data);
        }
        this.loading = false;
      },
      error: () => {
        this.alertService.error('An Error Occurred: loadHandlingSeverity');
        this.loading = false;
      }
    });
  }

  loadRegistrationSource() {

    this.loading = true;

    this.apiService.get('IncidentImprovement/ListRegistrationSource').pipe(
      takeUntil(this.unsubscribe),
    ).subscribe({
      next: (data) => {
        if (data) {
          this.RegistrationSource = Object.assign([], data);
        }
        this.loading = false;
      },
      error: () => {

        this.alertService.error('An Error Occurred: loadRegistrationSource');
        this.loading = false;

      }
    });
  }

  loadRegistrationType() {
    this.loading = true;

    this.apiService.get('IncidentImprovement/ListRegistrationType').pipe(
      takeUntil(this.unsubscribe),
    ).subscribe({
      next: (data) => {
        if (data) {
          this.RegistrationType = Object.assign([], data);
          this.RegistrationType$.next(data);
        }
        this.loading = false;
      },
      error: () => {
        this.alertService.error('An Error Occurred: loadRegistrationType');
        this.loading = false;
      }
    });
  }

  loadRegistrationDiscipline() {
    this.loading = true;

    this.apiService.get('IncidentImprovement/ListRegistrationDiscipline').pipe(
      takeUntil(this.unsubscribe),
    ).subscribe({
      next: (data) => {
        if (data) {
          this.RegistrationDiscipline = Object.assign([], data);
          this.RegistrationDiscipline$.next(data);
        }
        this.loading = false;
      },
      error: () => {
        this.alertService.error('An Error Occurred: loadRegistrationDiscipline');
        this.loading = false;
      }
    });
  }

  loadCurrencies() {
    this.loading = true;

    this.apiService.post('Currency/List').pipe(
      takeUntil(this.unsubscribe),
    ).subscribe({
      next: (data: ICurrency) => {
        if (data) {
          this.Currency = Object.assign([], data);
        }
        this.loading = false;
      },
      error: () => {
        this.alertService.error('An Error Occurred: loadCurrencies');
        this.loading = false;
      }
    });
  }

  loadAnalysisAction() {
    this.loading = true;

    this.apiService.get('IncidentImprovement/ListAnalysisAction').pipe(
      takeUntil(this.unsubscribe),
    ).subscribe({
      next: (data) => {
        if (data) {
          this.AnalysisAction = Object.assign([], data);
        }
        this.loading = false;
      },
      error: () => {
        this.alertService.error('An Error Occurred: loadAnalysisAction');
        this.loading = false;
      }
    });
  }

  loadAnalysisCauseSpecific() {
    this.loading = true;

    this.apiService.get('IncidentImprovement/ListAnalysisCauseSpecific').pipe(
      takeUntil(this.unsubscribe),
    ).subscribe({
      next: (data) => {
        if (data) {
          this.AnalysisCauseSpecific = Object.assign([], data);
          this.AnalysisCauseSpecific$.next(this.AnalysisCauseSpecific);
        }
        this.loading = false;
      },
      error: () => {
        this.alertService.error('An Error Occurred: loadAnalysisCauseSpecific');
        this.loading = false;
      }
    });
  }

  loadAnalysisCauseFactor() {
    this.loading = true;

    this.apiService.get('IncidentImprovement/ListAnalysisCauseFactor').pipe(
      takeUntil(this.unsubscribe),
    ).subscribe({
      next: (data) => {
        if (data) {
          this.AnalysisCauseFactor = Object.assign([], data);
        }
        this.loading = false;
      },
      error: () => {
        this.alertService.error('An Error Occurred: loadAnalysisCauseFactor');
        this.loading = false;
      }
    });
  }

  loadAnalysisCauseOccurrence() {
    this.loading = true;

    this.apiService.get('IncidentImprovement/ListAnalysisCauseOccurrence').pipe(
      takeUntil(this.unsubscribe),
    ).subscribe({
      next: (data) => {
        if (data) {
          this.AnalysisCauseOccurrence = Object.assign([], data);
        }
        this.loading = false;
      },
      error: () => {
        this.alertService.error('An Error Occurred: loadAnalysisCauseOccurrence');
        this.loading = false;
      }
    });
  }

  loadIncidentLevel() {
    this.loading = true;

    this.apiService.get('IncidentImprovement/ListIncidentLevel').pipe(
      takeUntil(this.unsubscribe),
    ).subscribe({
      next: (data) => {
        if (data) {
          this.IncidentLevel = Object.assign([], data);
        }
        this.loading = false;
      },
      error: () => {
        this.alertService.error('An Error Occurred: loadIncidentLevel');
        this.loading = false;
      }
    });
  }

  loadAllCompanies() {
    this.loading = true;
    this.Companies = [];

    this.apiService.get('Company/ListActive').pipe(
      takeUntil(this.unsubscribe),
    ).subscribe({
      next: (data: IActiveCompany[]) => {
        if (data) {
          this.Companies.push(...data.sort((a, b) => a.CompanyName < b.CompanyName ? -1 : 1));
        }
        this.loading = false;
      },
      error: () => {
        this.loading = false;
      }
    });
  }

  ////////////////////
  // RiskAssessment //
  ////////////////////
  getRiskAssessments(riskAssessmentListParam: IRiskAssessmentListParam): Observable<IPaginationData<IRiskAssessmentList>>{
    return this.apiService.post('RiskAssessment/List', riskAssessmentListParam).pipe(
      takeUntil(this.unsubscribe));
  }

  getRiskAssessmentById(riskAssessmentId: number) {
    return this.apiService.get(`RiskAssessment/${riskAssessmentId}`).pipe(
      takeUntil(this.unsubscribe));
  }

  updateRiskAssessment(riskAssessment: IRiskAssessmentDto) {
    return this.apiService.post('RiskAssessment/Update', riskAssessment).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  deleteRiskAssessment(riskAssessmentId: number) {
    return this.apiService.delete(`RiskAssessment/${riskAssessmentId}`).pipe(
      takeUntil(this.unsubscribe));
  }

  getRiskAssessmentReport(riskAssessmentId: number) {
    return this.apiService.get(`RiskAssessment/Report/${riskAssessmentId}`).pipe(
      takeUntil(this.unsubscribe));
  }

  getListRiskAssessmentItems(riskAssessmentId: number): Observable<IRiskAssessmentItem[]> {
    this.loading = true;
    return this.apiService.get(`RiskAssessment/AssessmentItem/List/${riskAssessmentId}`).pipe(
      takeUntil(this.unsubscribe));
  }

  searchAllRiskAssessmentItems(searchText: string): Observable<IRiskAssessmentItem[]> {
    this.loading = true;
    const param = { SearchText: searchText };
    return this.apiService.post('RiskAssessment/AssessmentItem/Search', param).pipe(
      takeUntil(this.unsubscribe));
  }

  upsertRiskAssessmentAnswer(riskAssessmentAnswer: IRiskAssessmentAnswer): Observable<IRiskAssessmentAnswer> {
    return this.apiService.post('RiskAssessment/Answer/Upsert', riskAssessmentAnswer).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  getRiskAssessmentGroups(): Observable<IRiskAssessmentGroup[]> {
    this.loading = true;
    return this.apiService.get('RiskAssessment/Group/List').pipe(
      takeUntil(this.unsubscribe));
  }

  getExposure() {
    this.loading = true;
    this.apiService.get('RiskAssessment/Exposure')
      .subscribe({
        next: (data) => {
          this.Exposure = Object.assign([], data);
          this.Exposure$.next(data);
        },
        error: () => {
        }
      });
  }

  getLikeliness() {
    this.loading = true;
    this.apiService.get('RiskAssessment/Likeliness')
      .subscribe({
        next: (data) => {
          this.Likeliness = Object.assign([], data);
          this.Likeliness$.next(data);
        },
        error: () => {
        }
      });
  }

  getSeverity() {
    this.loading = true;
    this.apiService.get('RiskAssessment/Severity')
      .subscribe({
        next: (data) => {
          this.Severity = Object.assign([], data);
          this.Severity$.next(data);
        },
        error: () => {
        }
      });
  }

  getEnvironmentalSeverity() {
    this.loading = true;
    this.apiService.get('EnvironmentalAssessment/Severity')
      .subscribe({
        next: (data) => {
          this.EnvironmentalSeverity = Object.assign([], data);
          this.EnvironmentalSeverity$.next(data);
        },
        error: () => {
        }
      });
  }

  getInfluence() {
    this.loading = true;
    this.apiService.get('RiskAssessment/Influence')
      .subscribe({
        next: (data) => {
          this.Influence = Object.assign([], data);
          this.Influence$.next(data);
        },
        error: () => {
        }
      });
  }

  getMeasure() {
    this.loading = true;
    this.apiService.get('RiskAssessment/Measure')
      .subscribe({
        next: (data) => {
          this.Measure = Object.assign([], data);
          this.Measure$.next(data);
        },
        error: () => {
        }
      });
  }

  getLevelOfMeasures() {
    this.loading = true;
    this.apiService.get('RiskAssessment/LevelOfMeasures')
      .subscribe({
        next: (data) => {
          this.LevelOfMeasures = Object.assign([], data);
          this.LevelOfMeasures$.next(data);
        },
        error: () => {
        }
      });
  }

  getRiskAssessmentAnswerStatus() {
    this.loading = true;
    this.apiService.get('RiskAssessment/Answer/Status/List')
      .subscribe({
        next: (data) => {
          this.RiskAssessmentAnswerStatus = Object.assign([], data);
          this.RiskAssessmentAnswerStatus$.next(data);
        },
        error: () => {
        }
      });
  }

  getBranchesByCompanyId(companyId: number) {
    return this.apiService.get(`Branch/Company/${companyId}`).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  getCompanyBranches(branchSearchParam: IBranchSearchDto, p: IPage, o: IOrderParam): Observable<IPaginationData<IBranchResultDto>>{
    return this.apiService.post(`Company/SearchBranches?pageNumber=${p.pageNumber}&pageSize=${p.pageSize}&orderBy=${o.OrderBy}&order=${o.OrderDirection}`, branchSearchParam).pipe(
      takeUntil(this.unsubscribe));
  }

  updateBranch(branch: IBranchResultDto) {
    return this.apiService.post(`Branch/Upsert`, branch).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  getRiskAssessmentStatus(){
    this.loading = true;
    this.apiService.get('RiskAssessment/Status/List')
      .subscribe({
        next: (data) => {
          this.RiskAssessmentStatus = Object.assign([], data);
          this.RiskAssessmentStatus$.next(data);
        },
        error: () => {}
      });
  }

  /////////////////////////////
  // Environmental Assessment //
  /////////////////////////////
  getEnvironmentalAssessments(environmentalAssessmentListParam: IEnvironmentalAssessmentListParam): Observable<IPaginationData<IEnvironmentalAssessmentList>>{
    return this.apiService.post('EnvironmentalAssessment/List', environmentalAssessmentListParam).pipe(
      takeUntil(this.unsubscribe));
  }

  getEnvironmentalAssessmentById(environmentalAssessmentId: number){
    return this.apiService.get(`EnvironmentalAssessment/${environmentalAssessmentId}`).pipe(
      takeUntil(this.unsubscribe));
  }

  updateEnvironmentalAssessment(environmentalAssessment: IEnvironmentalAssessmentDto) {
    return this.apiService.post('EnvironmentalAssessment/Update', environmentalAssessment).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  deleteEnvironmentalAssessment(environmentalAssessmentId: number) {
    return this.apiService.delete(`EnvironmentalAssessment/${environmentalAssessmentId}`).pipe(
      takeUntil(this.unsubscribe));
  }

  getEnvironmentalAssessmentReport(environmentalAssessmentId: number){
    return this.apiService.get(`EnvironmentalAssessment/Report/${environmentalAssessmentId}`).pipe(
      takeUntil(this.unsubscribe));
  }

  // EnvironmentalAssessmentItem
  getListEnvironmentalAssessmentItems(environmentalAssessmentId: number): Observable<IEnvironmentalAssessmentItem[]>{
    this.loading = true;
    return this.apiService.get(`EnvironmentalAssessment/AssessmentItem/List/${environmentalAssessmentId}`).pipe(
      takeUntil(this.unsubscribe));
  }


  // EnvironmentalAssessmentAnswer
  upsertEnvironmentalAssessmentAnswer(environmentalAssessmentAnswer: IEnvironmentalAssessmentAnswer): Observable<IEnvironmentalAssessmentAnswer> {
    return this.apiService.post('EnvironmentalAssessment/Answer/Upsert', environmentalAssessmentAnswer).pipe(
      takeUntil(this.unsubscribe)
    );
  }

  // EnvironmentalAssessmentItem
  getEnvironmentalAssessmentGroups(): Observable<IEnvironmentalAssessmentGroup[]>{
    this.loading = true;
    return this.apiService.get('EnvironmentalAssessment/Group/List').pipe(
      takeUntil(this.unsubscribe));
  }

  getEnvironmentalAssessmentAnswerStatus(){
    this.loading = true;
    this.apiService.get('EnvironmentalAssessment/Answer/Status/List')
      .subscribe({
        next: (data) => {
          this.EnvironmentalAssessmentAnswerStatus = Object.assign([], data);
          this.EnvironmentalAssessmentAnswerStatus$.next(data);
        },
        error: () => {}
      });
  }

  getEnvironmentalAssessmentStatus(){
    this.loading = true;
    this.apiService.get('EnvironmentalAssessment/Status/List')
      .subscribe({
        next: (data) => {
          this.EnvironmentalAssessmentStatus = Object.assign([], data);
          this.EnvironmentalAssessmentStatus$.next(data);
        },
        error: () => {}
      });
  }

  ///////////////////
  // Company Chart //
  ///////////////////
  getCompanyCharts(companyChartSearchParam: ICompanyChartSearchParam, p: IPage, o: IOrderParam): Observable<IPaginationData<ICompanyChartDto>>{
    return this.apiService
      .post(`CompanyChart/List?pageNumber=${p.pageNumber}&pageSize=${p.pageSize}&orderBy=${o.OrderBy}&order=${o.OrderDirection}`, companyChartSearchParam)
      .pipe(takeUntil(this.unsubscribe));
  }

  getCompanyChartById(companyChartId: number){
    return this.apiService.get(`CompanyChart/${companyChartId}`).pipe(
        takeUntil(this.unsubscribe));
  }

  getCompanyChartCategoriesByCompanyChartId(companyChartId: number): Observable<ICompanyChartCategories[]>{
    return this.apiService.get(`CompanyChart/CompanyChartCategories/List/${companyChartId}`).pipe(
      takeUntil(this.unsubscribe));
  }

  updateCompanyChartCategory(category: ICompanyChartCategory){
    return this.apiService.post('CompanyChart/Category/Update', category).pipe(
      takeUntil(this.unsubscribe));
  }

  deleteCompanyChartCategory(companyChartId: number, categoryId: number){
    return this.apiService.delete(`CompanyChart/Category/${companyChartId}/${categoryId}`).pipe(
      takeUntil(this.unsubscribe));
  }

  addCompanyChart(companyChart: ICompanyChartDto, useDefaultsCategories: boolean){
    return this.apiService.post(`CompanyChart/Add/${useDefaultsCategories}`, companyChart).pipe(
      takeUntil(this.unsubscribe));
  }

  updateCompanyChart(companyChart: ICompanyChartDto){
    return this.apiService.put('CompanyChart/Update', companyChart).pipe(
      takeUntil(this.unsubscribe));
  }

  deleteCompanyChart(companyChartId: number){
    return this.apiService.delete(`CompanyChart/${companyChartId}`).pipe(
      takeUntil(this.unsubscribe));
  }

  searchCompanyChartItems(companyChartItemsSearchParams: ICompanyChartItemsSearchParam): Observable<IPaginationData<ICompanyChartItemsSearchParam>>{
    return this.apiService.post(`CompanyChart/SearchCompanyChartItems/List`, companyChartItemsSearchParams).pipe(
      takeUntil(this.unsubscribe));
  }

  updateCompanyChartItem(companyChartItem: ICompanyChartItem){
    return this.apiService.post('CompanyChart/CompanyChartItem/Update', companyChartItem).pipe(
      takeUntil(this.unsubscribe));
  }

  deleteCompanyChartItem(companyChartItemId: number){
    return this.apiService.delete(`CompanyChart/CompanyChartItem/Delete/${companyChartItemId}`).pipe(
      takeUntil(this.unsubscribe));
  }

  getCompanyChartStatus() {
    this.loading = true;
    this.apiService.get('CompanyChart/CompanyChartStatus/List')
      .subscribe({
        next: (data) => {
          this.CompanyChartStatus = Object.assign([], data);
          this.CompanyChartStatus$.next(data);
        },
        error: () => { }
      });
  }

  addCompanyChartItemDocument(formData: FormData){
    return this.apiService.post('CompanyChart/Document', formData).pipe(takeUntil(this.unsubscribe));
  }

  updateDocumentDescription(document: ICompanyChartDocument){
    return this.apiService.post('CompanyChart/Document/Update', document).pipe(takeUntil(this.unsubscribe));
  }

  ///////////////
  // HSE Admin //
  ///////////////
  updateRiskAssessmentItem(riskAssessmentItem: IRiskAssessmentItem){
    this.loading = true;

    return this.apiService.put('RiskAssessment/AssessmentItem/Upsert', riskAssessmentItem).pipe(
      takeUntil(this.unsubscribe));
  }

  updateRiskAssessmentGroup(riskAssessmentGroup: IRiskAssessmentGroup) {
    this.loading = true;
    return this.apiService.put(`RiskAssessment/RiskAssessmentGroup`, riskAssessmentGroup).pipe(
      takeUntil(this.unsubscribe));
  }

  updateEnvironmentalAssessmentItem(environmentalAssessmentItem: IEnvironmentalAssessmentItem){
    this.loading = true;

    return this.apiService.put('EnvironmentalAssessment/AssessmentItem', environmentalAssessmentItem).pipe(
      takeUntil(this.unsubscribe));
  }

  searchAllEnvironmentalAssessmentItems(searchText: string): Observable<IEnvironmentalAssessmentItem[]> {
    this.loading = true;

    const param = { SearchText: searchText };

    return this.apiService.post('EnvironmentalAssessment/AssessmentItem/Search', param).pipe(
      takeUntil(this.unsubscribe));
  }

  updateEnvironmentalAssessmentGroup(environmentalAssessmentGroup: IEnvironmentalAssessmentGroup) {
    this.loading = true;
    return this.apiService.put(`EnvironmentalAssessment/EnvironmentalAssessmentGroup`, environmentalAssessmentGroup).pipe(
      takeUntil(this.unsubscribe));
  }

  deleteRiskAssessmentGroup(groupId: number) {
    this.loading = true;
    return this.apiService.delete(`RiskAssessment/Group/${groupId}`).pipe(
      takeUntil(this.unsubscribe));
  }
  deleteEnvironmentalAssessmentGroup(groupId: number) {
    this.loading = true;
    return this.apiService.delete(`EnvironmentalAssessment/EnvironmentalAssessmentGroup/${groupId}`).pipe(
      takeUntil(this.unsubscribe));
  }

  deleteBranch(branchId: number){
    this.loading = true;
    return this.apiService.delete(`Branch/${branchId}`).pipe(takeUntil(this.unsubscribe));
  }

  searchPSPhrases(param: IPhrasesSearchparam, p: IPage, o: IOrderParam): Observable<IPaginationData<IPSPhrase>>{
    this.loading = true;

    return this.apiService.post(`DangerousSubstanceRegister/SearchProtectionSafetyPhrases?pageNumber=${p.pageNumber}&pageSize=${p.pageSize}&orderBy=${o.OrderBy}&order=${o.OrderDirection}`, param).pipe(
      takeUntil(this.unsubscribe));
  }

  deletePSPhrase(phraseId: number) {
    this.loading = true;

    return this.apiService.delete(`DangerousSubstanceRegister/ProtectionSafetyPhrase/${phraseId}`).pipe(
      takeUntil(this.unsubscribe));
  }

  upsertPSPhrase(hrPhrase: IHRPhrase) {
    this.loading = true;

    return this.apiService.put(`DangerousSubstanceRegister/ProtectionSafetyPhrase`, hrPhrase).pipe(
      takeUntil(this.unsubscribe));
  }

  searchHRPhrases(param: IPhrasesSearchparam, p: IPage, o: IOrderParam): Observable<IPaginationData<IHRPhrase>>{
    this.loading = true;

    return this.apiService.post(`DangerousSubstanceRegister/SearchHazardRiskPhrases?pageNumber=${p.pageNumber}&pageSize=${p.pageSize}&orderBy=${o.OrderBy}&order=${o.OrderDirection}`, param).pipe(
      takeUntil(this.unsubscribe));
  }

  deleteHRPhrase(hrPhraseId: number) {
    this.loading = true;

    return this.apiService.delete(`DangerousSubstanceRegister/HazardRiskPhrase/${hrPhraseId}`).pipe(
      takeUntil(this.unsubscribe));
  }

  upsertHRPhrase(hrPhrase: IHRPhrase) {
    this.loading = true;

    return this.apiService.put(`DangerousSubstanceRegister/HazardRiskPhrase`, hrPhrase).pipe(
      takeUntil(this.unsubscribe));
  }

  checkRiskAssessmentComplete(riskAssessmentId: number){
    this.loading = true;

    return this.apiService.put(`RiskAssessment/CheckComplete/${riskAssessmentId}`).pipe(
      takeUntil(this.unsubscribe));
  }

  checkEnvironmentalAssessmentComplete(environmentalAssessmentId: number){
    this.loading = true;

    return this.apiService.put(`EnvironmentalAssessment/CheckComplete/${environmentalAssessmentId}`).pipe(
      takeUntil(this.unsubscribe));
  }

  public GetIncidentReportDashboard(registrationTypeId: number, disciplineId: number){
    this.loading = true;

    return this.apiService.get(`IncidentImprovement/Report/${registrationTypeId}/${disciplineId}`).pipe(
      takeUntil(this.unsubscribe));
  }

  public GetCompanyChartReportDashboard(){
    this.loading = true;

    return this.apiService.get(`CompanyChart/Report`).pipe(
      takeUntil(this.unsubscribe));
  }

  public GetEnvironmentalAssessmentReportDashboard(){
    this.loading = true;

    return this.apiService.get(`EnvironmentalAssessment/Report`).pipe(
      takeUntil(this.unsubscribe));
  }

  public GetRiskAssessmentReportDashboard(){
    this.loading = true;

    return this.apiService.get(`RiskAssessment/Report`).pipe(
      takeUntil(this.unsubscribe));
  }

  public GetPulseChartReportDashboard(){
    this.loading = true;

    return this.apiService.get(`PulseChart/Report`).pipe(
      takeUntil(this.unsubscribe));
  }
}
