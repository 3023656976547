import { Component, OnDestroy, OnInit } from '@angular/core';
import { faBoxOpen, faSyncAlt, faEdit, faFileExcel, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { IIncident, IIncidentImprovementListParam, IIncidentList } from '../../../services/models/hse/hse.model';
import { AdAuthService } from '../../../core/ad-auth-service/ad-auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IncidentImprovementModalComponent } from './incident-improvement-modal/incident-improvement-modal.component';
import { DatePipe } from '@angular/common';
import { HseService } from 'src/app/services/hse.service';
import { AlertService } from 'src/app/services/alert.service';
import { ApiService } from 'src/app/services/api.service';
import { IActiveCompany } from '../../../services/models/member.model';
import { IPage, IPaginationData } from '../../../shared/models/pagination-data.model';
import { FileService } from '../../../services/file.service';
import { CompanyService } from '../../../services/company.service';
import { OpenConfirmationModal } from '../../../shared/components/confirmation-modal/confirmation-modal-functions';
import { interval, Subject, take } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PermissionCodes } from '../../../core/constants/permission-codes';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { IOrderParam } from '../../../shared/directives/sort/order.directive';
import { getDayDifference } from '../../../shared/functions/datetime-functions';

@Component({
  selector: 'app-incident-improvement',
  templateUrl: './incident-improvement.component.html',
  styleUrls: ['./incident-improvement.component.scss'],
  providers: [
    DatePipe
  ]
})
export class IncidentImprovementComponent implements OnInit, OnDestroy {
  // Icons
  faEditIcon = faEdit;
  faEmpty = faBoxOpen;
  faRefresh = faSyncAlt;
  faExcel = faFileExcel;
  faSortDesc = faSortUp;

  // Component variables
  Incident: IIncident;
  IncidentId: number = null;
  CompanyId: number;
  incidentListParam: IIncidentImprovementListParam;
  companies: IActiveCompany[];
  canHideModal: any;
  paginationData: IPaginationData<IIncidentList>;
  orderParam: IOrderParam;
  page: IPage;

  // Lookup data
  companies$ = this.companyService.ActiveMembers$.asObservable();
  protected readonly getDayDifference = getDayDifference;

  // Permissions
  companyFilterAll: boolean = false;
  canExportExcel: boolean = false;

  // General variables
  loading: boolean = false;
  private unsubscribe: Subject<any> = new Subject<any>();
  public readonly PermissionCodes = PermissionCodes;
  environment = environment;

  constructor(public authService: AdAuthService,
              public hseService: HseService,
              private alertService: AlertService,
              public apiService: ApiService,
              private modalService: NgbModal,
              private route: ActivatedRoute,
              private companyService: CompanyService,
              private fileService: FileService,
              private router: Router) {
  }

  ngOnInit() {
    if (this.authService.CurrentUser) {
      this.setPermissions();
    }
    // Subscribe to user to always check if something changes
    this.authService.CurrentUser$.subscribe(() => {
      this.setPermissions();
    });
  }

  ngOnDestroy() {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  initPage(){
    // Set search param
    this.incidentListParam = {
      CompanyId: this.CompanyId,
      ShowAllCompanies: this.authService.CheckPermissionByCode(PermissionCodes.HSE_IncidentImprovement_CompanyFilter),
      Criteria: null,
      AnalysisStatusId: null,
      DisciplineId: null,
      Overdue: null
    };

    // Init pagination data
    this.paginationData = {
      DataSet: [],
      Data: null,
      CurrentPage: 1,
      PageSize: 30,
      TotalPages: 0
    };

    // Init page
    this.page = {
      pageNumber: this.paginationData.CurrentPage,
      pageSize: this.paginationData.PageSize,
      batched: false
    };

    // Set default ordering
    this.orderParam = {
      OrderBy: 'IncidentNumber',
      OrderDirection: 'desc'
    } as IOrderParam;
  }

  setPermissions() {
    // Check for Company Filter permission, only users with this permission can view Incidents for all members.
    this.companyFilterAll = this.authService.CheckPermissionByCode(PermissionCodes.HSE_IncidentImprovement_CompanyFilter);
    this.canExportExcel = this.authService.CheckPermissionByCode(PermissionCodes.HSE_IncidentImprovement_ExcelExport);
    this.CompanyId = this.companyFilterAll === true ? null : this.authService.CurrentUser.User.CompanyId;

    this.initPage();
    this.checkRoutes();
  }

  checkRoutes() {
    this.route.paramMap.pipe(take(1)).subscribe(paramMap => {
      const incidentId = paramMap.get('incidentId');
      const status = paramMap.get('status');
      // Open a specific incident
      if (incidentId) {
        if (this.IncidentId == null) {
          this.IncidentId = +incidentId;
          this.editIncident(+incidentId);
        }
      } else {
        this.IncidentId = null;
      }
      // Pre-filter based on optional parameters
      if (status != null) {
        this.initPage();

        if (status === 'Open') {
          const analysisStatus = this.hseService.AnalysisStatus.find(x => x.Description === status);
          this.incidentListParam.AnalysisStatusId = analysisStatus?.AnalysisStatusId;
        }

        if (status === 'Overdue') {
          this.incidentListParam.Overdue = true;
        }
      }
      this.loadIncidentList();
    });
  }

  onPage(page: IPage) {
    this.page = page;

    this.loadIncidentList();
  }

  orderSearch(param: IOrderParam) {
    this.orderParam = param;
    this.loadIncidentList();
  }

  editIncident(IncidentNumber: number) {
    this.loading = true;

    this.hseService.getIncident(IncidentNumber)
      .subscribe({
        next: (data: IIncident) => {
          if (data) {
            // Check if user can view this item
            if (this.companyFilterAll || data.CompanyId === this.CompanyId) {
              this.Incident = Object.assign({}, data);
              // Open modal
              const modalRef = this.modalService.open(IncidentImprovementModalComponent, {
                backdrop: 'static',
                size: 'lg'
              });

              modalRef.componentInstance.Incident = this.Incident;
              modalRef.componentInstance.isNew = false;
              if (modalRef.componentInstance.saveAndSubmit) {
                return false;
              }

              // On modal close, read the result and apply logic
              modalRef.result.then((result: boolean) => {
                if (result) {
                  this.loadIncidentList();
                }
              }, () => {
                this.canHideModal = false;
              });
              this.loading = false;
            } else {
              this.alertService.warn('You do not have access to view this incident');
              interval(1000).pipe(takeUntil(this.unsubscribe)).subscribe(() => {
                this.router.navigate(['hse/incident-improvement']).catch();
              });
            }
          }
        },
        error: () => {
          this.alertService.error('An Error Occurred: IncidentList');
          this.loading = false;
        }
      });
  }

  deleteIncident(IncidentNumber: number) {
    this.loading = true;
    const message: string = 'Are you sure you want to delete this incident improvement';
    OpenConfirmationModal(this.modalService, message)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((answer: boolean) => {
        if (answer) {
          this.hseService.deleteIncident(IncidentNumber).subscribe({
            next: (data) => {
              this.Incident = Object.assign({}, data);
              this.loadIncidentList();
              this.loading = false;
            },
            error: () => {
              this.alertService.error('An Error Occurred: deleteIncident');
              this.loading = false;
            }
          });
        }
      });
  }

  loadIncidentList() {
    this.loading = true;

    this.hseService.getIncidentsList(this.incidentListParam, this.orderParam, this.page).subscribe({
      next: (data: IPaginationData<IIncidentList>) => {
        if (data) {
          this.paginationData = Object.assign({}, data);
        }
        this.loading = false;
      },
      error: () => {
        this.alertService.error('An Error Occurred: Load IncidentList');
        this.loading = false;
      }
    });
  }

  resetSearch() {
    this.initPage();
    this.loadIncidentList();
  }

  setOverdue(event: any) {
    this.page.pageNumber = 1;
    this.paginationData.CurrentPage = 1;
    if (event.target.checked === true) {
      this.incidentListParam.Overdue = true;
    } else {
      this.incidentListParam.Overdue = null;
    }
    this.loadIncidentList();
  }

  registerNewIncident() {
    // Open modal
    const modalRef = this.modalService.open(IncidentImprovementModalComponent, {size: 'lg', backdrop: 'static'});
    modalRef.componentInstance.Incident = ({} as IIncident);
    modalRef.componentInstance.isNew = true;

    // On modal close, read the result and apply logic
    modalRef.result.then((result: IIncident) => {
      if (result) {
        this.loadIncidentList();
      }
    }, () => {
    });
  }

  excelExport() {
    this.loading = true;

    this.hseService.excelExport().subscribe({
      next: (response) => {
        const fileName = this.fileService.GetFileName(response);
        this.fileService.DownloadFile(response, fileName);
        this.loading = false;
      },
      error: () => {
        this.alertService.error('An error occurred while trying to export to Excel.');
        this.loading = false;
      }
    });
  }
}
